<template>
  <form-table
    :table-header-label="'Ingredients'"
    :add-row-label="'Ingredient'"
    :table-contents="tableData"
    @add-row="addTableRow"
  >
    <template #headings>
      <th>Trade Name</th>
      <th>INCI Name</th>
      <th>CAS Number</th>
      <th>Supplier</th>
      <th>% W/W</th>
      <th></th>
    </template>

    <template #rows="{ data }">
      <tr v-for="(ingredient, index) in data" :key="ingredient.id">
        <td v-if="ingredient.trade_name === ''">
          <dynamic-select
            name="ingredient_id"
            placeholder="Select Ingredient"
            :route="route"
            @update:modelValue="selectIngredient(index, $event)"
          ></dynamic-select>
        </td>
        <td v-else>
          <input
            v-model="ingredient.trade_name"
            class="form-control"
            type="text"
            disabled
          />
        </td>
        <td>
          <input
            v-model="ingredient.inci_name"
            class="form-control"
            type="text"
            disabled
          />
        </td>
        <td>
          <input
            v-model="ingredient.cas_number"
            class="form-control"
            type="text"
            disabled
          />
        </td>
        <td>
          <input
            v-model="ingredient.supplier"
            class="form-control"
            type="text"
            required
          />
        </td>
        <td>
          <input
            v-model="ingredient.percentage"
            class="form-control"
            type="text"
            required
          />
        </td>
        <td>
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="removeRow(index)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
    </template>
  </form-table>
  <input type="hidden" name="ingredients" :value="serializedTableData" />
</template>

<script setup>
import { reactive, computed, onMounted } from "vue";
import FormTable from "@/components/dynamicFormTable.vue";
import DynamicSelect from "@/components/dynamicSelect.vue";

const props = defineProps({
  tableData: {
    type: String,
    default: "",
  },
  selectRoute: {
    type: String,
    required: true,
  },
});

const tableData = reactive(JSON.parse(props.tableData || "[]"));
const route = props.selectRoute;

const addTableRow = () => {
  tableData.push({
    id: null,
    inci_name: "",
    trade_name: "",
    cas_number: "",
    supplier: "",
    percentage: "",
  });
};

const removeRow = (index) => {
  tableData.splice(index, 1);
};

const selectIngredient = (index, selectedIngredient) => {
  if (selectedIngredient) {
    tableData[index].id = selectedIngredient.id;
    tableData[index].inci_name = selectedIngredient.inci_name;
    tableData[index].trade_name = selectedIngredient.trade_name;
    tableData[index].cas_number = selectedIngredient.cas_number;
  }
};

const serializedTableData = computed(() => JSON.stringify(tableData));

onMounted(() => {
  Eventbus.emit("watch-me");
});
</script>
