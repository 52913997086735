<template>
  <th role="button" @click="$emit('setOrderBy', orderBy)">
    <slot />
    <span v-if="currentOrderBy == orderBy" v-html="direction"></span>
  </th>
</template>

<script setup>
import { computed } from "vue";

defineEmits(["setOrderBy"]);

const props = defineProps({
  currentOrderBy: String,
  orderBy: String,
  orderAsc: Boolean,
});

const direction = computed(() => (props.orderAsc ? "↑" : "↓"));
</script>
