<template>
  <form-table
    :table-header-label="tableHeaderLabel"
    :add-row-label="'Allergen'"
    :table-contents="tableData"
    @add-row="addTableRow"
  >
    <template #headings>
      <th>Allergen Name (INCI)</th>
      <th>CAS Number</th>
      <th>% W/W</th>
      <th>#</th>
    </template>

    <template #rows="{ data }">
      <tr v-for="(allergen, index) in data" :key="allergen.id">
        <td v-if="allergen.inci_name === ''">
          <dynamic-select
            name="allergen_id"
            placeholder="Select Allergen"
            :route="route"
            @update:modelValue="selectAllergen(index, $event)"
          ></dynamic-select>
        </td>
        <td v-else>
          <input
            v-model="allergen.inci_name"
            class="form-control"
            type="text"
            disabled
          />
        </td>
        <td>
          <input
            v-model="allergen.cas_number"
            class="form-control"
            type="text"
            disabled
          />
        </td>
        <td>
          <input
            v-model="allergen.percentage"
            class="form-control"
            type="text"
            required
          />
        </td>
        <td>
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="removeRow(index)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
    </template>
  </form-table>
  <input type="hidden" name="allergens" :value="serializedTableData" />
</template>

<script setup>
import { reactive, computed, onMounted } from "vue";
import FormTable from "@/components/dynamicFormTable.vue";
import DynamicSelect from "@/components/dynamicSelect.vue";

const props = defineProps({
  tableData: {
    type: String,
    default: "",
  },
  selectRoute: {
    type: String,
    required: true,
  },
  tableHeaderLabel: {
    type: String,
    required: false,
    default: "Allergens",
  },
});

const tableData = reactive(JSON.parse(props.tableData || "[]"));
const route = props.selectRoute;

const addTableRow = () => {
  tableData.push({
    id: null,
    inci_name: "",
    cas_number: "",
    percentage: "",
  });
};

const removeRow = (index) => {
  tableData.splice(index, 1);
};

const selectAllergen = (index, selectedAllergen) => {
  if (selectedAllergen) {
    tableData[index].id = selectedAllergen.id;
    tableData[index].inci_name = selectedAllergen.text;
    tableData[index].cas_number = selectedAllergen.cas_number;
  }
};

const serializedTableData = computed(() => JSON.stringify(tableData));

onMounted(() => {
  Eventbus.emit("watch-me");
});
</script>
