<template>
  <form
    ref="form"
    :method="method"
    :action="action"
    @submit.prevent="submitForm"
  >
    <slot></slot>
  </form>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount } from "vue";

const props = defineProps({
  action: {
    type: String,
    required: true,
  },
  method: {
    type: String,
    default: "POST",
  },
});

const form = ref();
const initialFormData = reactive({});
const hasFormChanged = ref(false);
const isSubmitting = ref(false);

const getFormData = () => {
  const formData = new FormData(form.value);
  return Object.fromEntries(formData.entries());
};

const checkForm = () => {
  hasFormChanged.value =
    JSON.stringify(initialFormData) !== JSON.stringify(getFormData());
};

const handleBeforeUnload = (event) => {
  if (hasFormChanged.value && !isSubmitting.value) {
    const message = "You have unsaved changes. Are you sure you want to leave?";
    event.returnValue = message;
    return message; // apparently for some older browsers
  }
};

const submitForm = () => {
  isSubmitting.value = true;
  form.value.submit();
};

onMounted(() => {
  window.addEventListener("beforeunload", handleBeforeUnload);
  Object.assign(initialFormData, getFormData()); // do this for page with no vue component to fire event

  setInterval(() => {
    checkForm();
  }, 1000);
});

onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
});

Eventbus.on("watch-me", () => {
  Object.assign(initialFormData, getFormData());
});
</script>
