<template>
  <div
    :id="'threshold-modal-' + productId"
    class="modal"
    tabindex="-1"
    aria-labelledby="threshold-modal"
    aria-hidden="true"
    style="display: none"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form
          :id="'thresholdForm' + productId"
          method="POST"
          :action="exportRoute"
          target="_blank"
        >
          <div class="block block-rounded block-transparent mb-0">
            <div class="block-header block-header-default">
              <h3 class="block-title">Cutoff Threshold</h3>
              <div class="block-options">
                <button
                  type="button"
                  class="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa fa-fw fa-times"></i>
                </button>
              </div>
            </div>
            <div class="block-content block-content-full">
              <p class="text-muted">
                Please select the cutoff threshold for percentage amounts.
              </p>
              <input type="hidden" name="_token" :value="csrf" />
              <div class="form-group mb-3">
                <select class="form-select" name="thresholdSelect">
                  <option selected disabled>
                    -- Please select threshold --
                  </option>
                  <option value="0.001">0.001%</option>
                  <option value="0.01">0.01%</option>
                </select>
              </div>
            </div>
            <div
              class="block-content block-content-full d-flex justify-content-between bg-body"
            >
              <button
                type="button"
                class="btn btn-outline-info"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-primary me-1"
                data-bs-dismiss="modal"
              >
                Export
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  exportRoute: {
    type: String,
    required: true,
  },
  productId: {
    required: true,
  },
});

const csrf = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute("content");
</script>
