<template>
  <datatable
    :url="ajaxUrl"
    :create-url="createUrl"
    :order-by="orderBy"
    :order-asc="orderAsc"
    refresh-on="reloadTable"
    search-placeholder="Search all products..."
  >
    <template #headings>
      <sortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="name"
        @set-order-by="setOrder"
      >
        Name
      </sortableTh>
      <sortableTh
        class="text-center"
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="status"
        @set-order-by="setOrder"
      >
        Status
      </sortableTh>
      <sortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="created_at"
        @set-order-by="setOrder"
      >
        Created at
      </sortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="product in data" :key="product.id">
        <td>{{ product.name }}</td>
        <td class="text-center" v-html="product.statusPill"></td>
        <td>{{ product.created_at }}</td>
        <td>
          <a
            class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap btn-primary me-2"
            :href="`${product.actions.edit}`"
          >
            <i class="fa fa-fw fa-eye"></i>
            View
          </a>
          <button
            id="dropdown-default-dark"
            type="button"
            class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap dropdown-toggle btn-dark me-2"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-regular fa-file-pdf"></i>
            Export
          </button>
          <div
            class="dropdown-menu fs-sm"
            aria-labelledby="dropdown-default-dark"
          >
            <a
              class="dropdown-item"
              :href="`${product.actions.export_allergens}`"
              target="_blank"
            >
              Allergens
            </a>
            <a
              id="exportInciList"
              class="dropdown-item"
              data-bs-toggle="modal"
              :data-bs-target="`#threshold-modal-${product.id}`"
            >
              INCI List
            </a>
            <a
              class="dropdown-item"
              :href="`${product.actions.export_makeup}`"
              target="_blank"
            >
              Makeup
            </a>
          </div>
          <a
            v-if="product.isApproved"
            class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap btn-success me-2"
            data-bs-toggle="modal"
            :data-bs-target="`#email-modal-${product.id}`"
          >
            <i class="fa-regular fa-envelope"></i>
            Email
          </a>
          <button
            v-if="product.status == 'Draft'"
            class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap btn-danger me-2"
          >
            <i class="fa fas fa-times"></i>
            Cancel Approval
          </button>
          <button
            v-else-if="product.status == 'Approved'"
            class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap btn-danger me-2"
          >
            <i class="fa fas fa-trash me-2"></i>
            Delete
          </button>
          <export-threshold
            :export-route="`${product.actions.export_inci}`"
            :product-id="product.id"
          ></export-threshold>
          <email-report
            v-if="product.isApproved"
            :email-route="`${product.actions.notify}`"
            :product-id="product.id"
            :client-email="product.clientEmail"
          ></email-report>
        </td>
      </tr>
    </template>
  </datatable>
</template>

<script setup>
import { ref } from "vue";
import datatable from "@/components/datatable.vue";
import sortableTh from "@/components/datatable/sortableTh.vue";
import exportThreshold from "@/components/threshold.vue";
import emailReport from "@/components/products/emailReports.vue";

const props = defineProps({
  ajaxUrl: String,
  createUrl: String,
});

const orderBy = ref("created_at");
const orderAsc = ref(false);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
