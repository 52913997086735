<template>
  <input
    v-if="name !== undefined"
    type="hidden"
    :name="name"
    :value="value === undefined || value === null ? '' : value.id"
  />

  <v-select
    v-model="value"
    :options="options"
    :placeholder="props.placeholder"
    label="text"
    :disabled="props.disabled"
    @search="search"
  />
</template>

<script setup>
import debounce from "lodash/debounce";
import vSelect from "vue-select";
import { onMounted, ref, watch } from "vue";
import { useFetch } from "@/utilities/useFetch.js";

const props = defineProps({
  name: String,
  placeholder: String,
  route: String,
  defaultValue: String,
  disabled: Boolean,
});

const options = ref([]);
const value = defineModel();

let fetchAbort, fetchAbortSignal;
async function ajaxSearch(searchTerm, loading) {
  if (!props.route) {
    return;
  }

  loading = loading || function () {};

  options.value = [];
  loading(true);

  // abort the old one if there was one
  if (fetchAbort !== undefined) {
    fetchAbort.abort();
  }
  // reinitialise the abort controller for each new request
  if ("AbortController" in window) {
    fetchAbort = new AbortController();
    fetchAbortSignal = fetchAbort.signal;
  }

  try {
    let urlToFetch = new URL(props.route);
    if (searchTerm) {
      urlToFetch.searchParams.set("search", searchTerm);
    }

    const res = await useFetch(urlToFetch, { signal: fetchAbortSignal });

    let response = await res.json();

    options.value = response.data;
  } catch (err) {
    throw err;
  } finally {
    loading(false);
  }
}

const search = debounce(ajaxSearch, 350);

const setDefaultValue = async function () {
  if (!props.defaultValue) {
    return;
  }
  const urlToFetch = new URL(props.route);
  urlToFetch.searchParams.set("id", props.defaultValue);

  const res = await useFetch(urlToFetch);
  const json = await res.json();

  value.value = json.data[0];
};

onMounted(() => {
  search();

  setDefaultValue();
});

watch(
  () => props.route,
  () => search()
);
</script>
