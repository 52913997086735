<template>
  <div
    :id="'email-modal-' + productId"
    class="modal"
    tabindex="-1"
    aria-labelledby="email-modal"
    aria-hidden="true"
    style="display: none"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form
          :id="'email-form-' + productId"
          method="POST"
          :action="emailRoute"
        >
          <div class="block block-rounded block-transparent mb-0">
            <div class="block-header block-header-default">
              <h3 class="block-title">Email Client</h3>
              <div class="block-options">
                <button
                  type="button"
                  class="btn-block-option"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa fa-fw fa-times"></i>
                </button>
              </div>
            </div>
            <div class="block-content block-content-full">
              <p class="text-muted">
                Please confirm relevant information for sending this email.
              </p>
              <input type="hidden" name="_token" :value="csrf" />
              <div class="form-group mb-3">
                <label :for="'toAddress' + productId" class="form-label">
                  To
                </label>
                <input
                  :id="'toAddress' + productId"
                  v-model="toAddress"
                  class="form-control mb-3"
                  type="text"
                  disabled
                />
              </div>
              <div class="form-group mb-3">
                <label :for="'ccs' + productId" class="form-label">CCs</label>
                <input
                  :id="'ccs' + productId"
                  class="form-control mb-3"
                  type="text"
                  name="ccs"
                  placeholder="Enter email addresses separated by comma"
                />
              </div>
              <div class="form-group mb-3">
                <label class="mb-2">Reports</label>
                <div class="form-check">
                  <input
                    :id="'allergens' + productId"
                    v-model="sendAllergens"
                    class="form-check-input"
                    type="checkbox"
                    name="allergens"
                    :value="true"
                  />
                  <label
                    class="form-check-label"
                    :for="'allergens' + productId"
                  >
                    Allergens
                  </label>
                </div>
                <div class="form-check">
                  <input
                    :id="'makeup' + productId"
                    v-model="sendMakeup"
                    class="form-check-input"
                    type="checkbox"
                    name="makeup"
                    :value="true"
                  />
                  <label class="form-check-label" :for="'makeup' + productId">
                    Makeup
                  </label>
                </div>
                <div class="form-check">
                  <input
                    :id="'inciList' + productId"
                    v-model="sendInciList"
                    class="form-check-input"
                    type="checkbox"
                    name="inciList"
                    :value="true"
                  />
                  <label class="form-check-label" :for="'inciList' + productId">
                    INCI List
                  </label>
                </div>
              </div>
              <div v-if="thresholdNeeded" class="form-group mb-5">
                <p class="text-muted">Please select threshold for INCI list.</p>
                <select
                  id="thresholdSelect"
                  class="form-select"
                  name="thresholdSelect"
                >
                  <option selected disabled>
                    -- Please select threshold --
                  </option>
                  <option value="0.001">0.001%</option>
                  <option value="0.01">0.01%</option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="block-content block-content-full d-flex justify-content-between bg-body"
          >
            <button
              type="button"
              class="btn btn-outline-info"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              class="btn btn-primary me-1"
              data-bs-dismiss="modal"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  emailRoute: {
    type: String,
    required: true,
  },
  productId: {
    required: true,
  },
  clientEmail: {
    required: true,
  },
});

const thresholdNeeded = ref(false);
const sendAllergens = ref(false);
const sendMakeup = ref(false);
const sendInciList = ref(false);
const toAddress = ref(props.clientEmail);

const csrf = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute("content");

watch(sendInciList, (value) => {
  thresholdNeeded.value = value;
});
</script>
