<template>
  <div class="block block-rounded">
    <div class="block-header block-header-default">
      <h3 class="block-title">{{ tableHeaderLabel }}</h3>
      <div class="block-options">
        <div class="block-options-item">
          <code v-if="tableHeaderSmall">{{ tableHeaderSmall }}</code>
        </div>
      </div>
    </div>
    <div class="block-content">
      <table class="table table-bordered table-striped table-vcenter">
        <thead>
          <tr>
            <slot name="headings"></slot>
          </tr>
        </thead>
        <tbody>
          <tr v-if="props.tableContents.length == 0">
            <td colspan="99" class="text-center">Nothing to show</td>
          </tr>
          <slot v-else name="rows" :data="props.tableContents"></slot>
          <tr>
            <td colspan="99" class="text-center">
              <a class="btn btn-sm btn-alt-info" @click="addRow()">
                <i class="fa fa-plus"></i>
                Add {{ addRowLabel }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  refreshOn: String,
  tableHeaderLabel: {
    type: String,
    default: "Items",
  },
  tableHeaderSmall: {
    type: String,
    default: "",
  },
  tableContents: {
    type: Object,
    default: () => {},
  },
  addRowLabel: {
    type: String,
    default: "item",
  },
});

const emit = defineEmits(["add-row"]);

const addRow = () => {
  emit("add-row");
};
</script>
