import { createApp } from "vue";
import Swal from "sweetalert2";
import flatpickr from "flatpickr";
import EmailReports from "@/components/products/emailReports.vue";
import { Eventbus } from "@/utilities/eventbus.js";
import { useFetch } from "@/utilities/useFetch.js";

import CustomForm from "@/components/form.vue";
import AllergenList from "@/components/allergens/list.vue";
import ComponentList from "@/components/components/list.vue";
import ComponentMakeupTable from "@/components/components/formTable.vue";
import CustomerList from "@/components/customers/list.vue";
import DarkModeToggle from "@/components/darkModeToggle.vue";
import FormulaComponents from "@/components/formulas/formTable.vue";
import FormulaList from "@/components/formulas/list.vue";
import IngredientList from "@/components/ingredients/list.vue";
import IngredientAllergensTable from "@/components/ingredients/formTable.vue";
import ProductList from "@/components/products/list.vue";
import UsersList from "@/components/users/list.vue";
import ExportThreshold from "@/components/threshold.vue";

window.Swal = Swal;
window.Eventbus = Eventbus;
window.Toast = Swal.mixin({
  toast: true,
  position: "bottom-start",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
});

const components = {
  "custom-form": CustomForm,
  "allergens-list": AllergenList,
  "components-list": ComponentList,
  "component-makeup": ComponentMakeupTable,
  "customers-list": CustomerList,
  "dark-mode-toggle": DarkModeToggle,
  "formula-components": FormulaComponents,
  "formulas-list": FormulaList,
  "ingredient-allergens": IngredientAllergensTable,
  "ingredients-list": IngredientList,
  "products-list": ProductList,
  "users-list": UsersList,
  "export-threshold": ExportThreshold,
  "email-report": EmailReports,
};

let n = 0;
for (const tag in components) {
  for (const el of document.querySelectorAll(tag)) {
    const app = createApp({
      name: `${tag} ${++n}`,
      template: el.outerHTML,
    });
    app.component(tag, components[tag]);
    app.mount(el);
  }
}

window.document
  .querySelector("#main-container")
  ?.addEventListener("click", (e) => {
    const button = e.target;
    if (e.target.classList.contains("js-confirmDelete")) {
      const deleteText = button.getAttribute("data-delete-text");

      confirmHandler(
        "Delete",
        deleteText,
        "Do you want to delete this?",
        "delete",
        button.getAttribute("data-delete-href"),
        "Deleted",
        button.getAttribute("data-delete-then"),
        true
      );
    }
  });

let confirmHandler = async function (
  confirmButton,
  confirmDetails,
  confirmQuestion,
  fetchAction,
  requestHref,
  successTitle,
  successEmit,
  isTable
) {
  const result = await Swal.fire({
    title: confirmQuestion,
    html: confirmDetails,
    icon: "question",
    showCancelButton: true,
    confirmButtonText: confirmButton,
    customClass: {
      confirmButton: "bg-danger",
    },
    reverseButtons: true,
    focusConfirm: false,
  });

  if (!result.isConfirmed) {
    return;
  }

  const response = await useFetch(requestHref, {
    method: fetchAction,
  });

  if (!response.ok) {
    alert("System error");
    criteria.loading = false;
  }

  const data = await response.json();

  if (data?.message.type === "error") {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: data.message.title,
      customClass: {
        confirmButton: "bg-danger",
      },
      showConfirmButton: true,
    });

    return;
  } else {
    Swal.fire({
      icon: "success",
      title: successTitle,
      timer: 1500,
      timerProgressBar: true,
      showConfirmButton: false,
    }).then(() => {
      if (isTable === false) {
        location.reload();
      } else if (successEmit) {
        Eventbus.emit(successEmit);
      }
    });
  }
};

flatpickr(".js-flatpickers");
