<template>
  <datatable
    :url="ajaxUrl"
    :create-url="createUrl"
    :order-by="orderBy"
    :order-asc="orderAsc"
    refresh-on="reloadTable"
    search-placeholder="Search all clients..."
  >
    <template #headings>
      <sortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="name"
        @set-order-by="setOrder"
      >
        Name
      </sortableTh>
      <sortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="email"
        @set-order-by="setOrder"
      >
        Email
      </sortableTh>
      <th>Head Office Address</th>
      <sortableTh
        :current-order-by="orderBy"
        :order-asc="orderAsc"
        order-by="created_at"
        @set-order-by="setOrder"
      >
        Created at
      </sortableTh>
      <th></th>
    </template>
    <template #rows="{ data }">
      <tr v-for="customer in data" :key="customer.id">
        <td>{{ customer.name }}</td>
        <td>{{ customer.email }}</td>
        <td>{{ customer.address }}</td>
        <td>{{ customer.created_at }}</td>
        <td v-html="customer.actions"></td>
      </tr>
    </template>
  </datatable>
</template>

<script setup>
import { ref } from "vue";
import datatable from "@/components/datatable.vue";
import sortableTh from "@/components/datatable/sortableTh.vue";

const props = defineProps({
  ajaxUrl: String,
  createUrl: String,
});

const orderBy = ref("created_at");
const orderAsc = ref(false);

const setOrder = function (input) {
  if (orderBy.value == input) {
    orderAsc.value = !orderAsc.value;
  } else {
    orderBy.value = input;
    orderAsc.value = true;
  }
};
</script>
